import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/templates/MDXDocs.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "members",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#members",
        "aria-label": "members permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Members`}</h1>
    <h3 {...{
      "id": "sdkmembersdiscovermemberoptions",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#sdkmembersdiscovermemberoptions",
        "aria-label": "sdkmembersdiscovermemberoptions permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`sdk.members.discoverMember(options)`}</h3>
    <h4 {...{
      "id": "options",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#options",
        "aria-label": "options permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`options`}</h4>
    <blockquote>
      <p parentName="blockquote"><inlineCode parentName="p">{`Object`}</inlineCode>{` | required`}</p>
    </blockquote>
    <p>{`Discover a member using query parameters.`}</p>
    <p>{`The response will be the matching member's internal ID. If a `}<inlineCode parentName="p">{`custodians`}</inlineCode>{` array field is also present, the
matching member is a dependant. Each custodian object contains the custodian's member ID, first name and
last name. The custodian is important when creating an invoice for a dependant because the claim/payment
authorisation can only be performed by a custodian. A dependant inherits their custodians' email and mobile,
and therefore will be included in such a search.`}</p>
    <p>{`Email and mobile are the primary search parameters. The order of search parameter priorities are as
follows: `}<inlineCode parentName="p">{`email`}</inlineCode>{` > `}<inlineCode parentName="p">{`mobile`}</inlineCode>{` > `}<inlineCode parentName="p">{`dobString`}</inlineCode>{` > `}<inlineCode parentName="p">{`lastName`}</inlineCode>{` > `}<inlineCode parentName="p">{`firstName`}</inlineCode>{`.`}</p>
    <p>{`The following search sets are recommended:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`{ email }`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`{ mobile, dobString }`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`{ mobile, firstName, lastName }`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`{ dobString, firstName, lastName }`}</inlineCode></li>
    </ul>
    <p>{`The following query sets are not allowed:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`{ firstName }`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`{ lastName }`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`{ dobString }`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`{ firstName, dobString }`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`{ lastName, dobString }`}</inlineCode></li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`{
  query: {
    email: string,
    mobile: string,
    dobString: string, // "YYYY-MM-DD"
    firstName: string,
    lastName: string
  }
}
`}</code></pre>
    <h4 {...{
      "id": "example",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#example",
        "aria-label": "example permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Example`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`medipassTransactionSDK.members.discoverMember({ email: 'john@example.com' });
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      